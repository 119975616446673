<template>
	<div class="perform-dash" :class="computedClass" :style="computedStyle">
		<div
			class="perform-dash__bg"
			:class="isMobileView && 'perform-dash__bg_hidden'"
			:style="`background-image: url(${computedHeader})`"
		/>

		<v-container fluid class="perform-dash__container">
			<header class="perform-dash__header">
				<PerformDashTitle
					v-model="activeDashIndex"
					:tabs="computedDashboards"
					:doShowTabs="doShowTabs"
					@click-lock="showDashboardLockedDialog"
					@update:doShowTabs="doShowTabs = $event"
				/>
				<div class="perform-dash__header-filters">
					<div class="perform-dash__period-switcher">
						<v-switch
							v-model="monthlyPeriod"
							:disabled="dashboardsLoading"
							dark
							hide-details
							inset
							:label="monthlyPeriod ? 'Monthly' : 'Weekly'"
							class="perform-dash-period-switcher"
						/>
					</div>
                    <QAgentLookup
						v-if="hasRole(['AgencyOwner', 'Staff', 'Exec', 'SuperAdmin'])"
						v-model="agent"
						:filters="{ Status : 'Active,Available,Stalled' }"
						:agent-attrs="['Permissions']"
						return-object
						:disabled="dashboardsLoading"
						label="Agent"
						placeholder="Start typing to Search for Agent"
						hide-details
						preload-agents
						dark
						class="perform-dash__agent-lookup"
					/>
				</div>
			</header>

			<div v-if="activeDashRoute" class="perform-dash__dashboard">
				<component
					:is="activeDashComponent"
					:agent="currentAgent"
					:monthly-period="monthlyPeriod"
					:card-mobile-order="isCardMobileOrder"
					:info-data="cmsPage"
				/>
			</div>
		</v-container>

		<NewDashboardUnlockedDialog
			v-model="doShowNewDashboardUnlockedDialog"
			:content="newDashboardUnlockedDialogContent"
			:loading="cmsPage.loading"
		/>

		<InfoDialog
			v-model="doShowDashboardLockedDialog"
			title="Dashboard Locked"
			:content="dashboardLockedDialogContent"
			:loading="cmsPage.loading"
		/>
	</div>
</template>

<script>
import Page from '@/store/Models/Page'
import QuilityAPI from '@/store/API/QuilityAPI'

import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import InfoDialog from './Components/InfoDialog.vue'
import NewDashboardUnlockedDialog from './Components/NewDashboardUnlockedDialog.vue'
import ActivatePhase from './ActivatePhase.vue'
import AcceleratePhase from './AcceleratePhase.vue'
import AdvancePhase from './AdvancePhase.vue'
import PerformDashTitle from './Components/PerformDashTitle.vue'

import LeadershipLevelsMixin from './mixins/leadership-levels-mixin'

const ACTIVATE = 'activate'
const ACCELERATE = 'accelerate'
const ADVANCE = 'advance'
const ACHIEVE = 'achieve'

const dashboardsConfig = [
	{
		phase: 1,
		tabIndex: 0,
		title: 'Activate',
		route: ACTIVATE,
		disabled: false,
		dashBg: '#000F2C',
		cardBg: '#073150',
		headerBg: require('./style/PerformDash-Activate-Header.svg'),
		amplitudeTrackingID: 'PerfDash_Activate',
	},
	{
		phase: 2,
		tabIndex: 1,
		title: 'Accelerate',
		route: ACCELERATE,
		disabled: true,
		dashBg: '#021A18',
		cardBg: '#0B302D',
		headerBg: require('./style/PerformDash-Accelerate-Header.svg'),
		newDashboardUnlockedBlockTitle: 'Accelerate: Unlocked',
		dashboardLockedBlockTitle: 'Accelerate: Locked',
		amplitudeTrackingID: 'PerfDash_Accelerate',
	},
	{
		phase: 3,
		tabIndex: 2,
		title: 'Advance',
		route: ADVANCE,
		disabled: true,
		dashBg: '#031036',
		cardBg: '#002265',
		headerBg: require('./style/PerformDash-Advance-Header.svg'),
		newDashboardUnlockedBlockTitle: 'Advance: Unlocked',
		dashboardLockedBlockTitle: 'Advance: Locked',
		amplitudeTrackingID: 'PerfDash_Advance',
	},
	{
		phase: 4,
		tabIndex: 3,
		title: 'Achieve',
		route: ACHIEVE,
		disabled: true,
		dashBg: '#1a0e02',
		cardBg: '#432111',
		headerBg: null,
		newDashboardUnlockedBlockTitle: 'Achieve: Unlocked',
		dashboardLockedBlockTitle: 'Achieve: Locked',
		amplitudeTrackingID: 'PerfDash_Achieve',
	},
]

const dashboardsParamsMap = {
	[ACTIVATE]: { component: ActivatePhase },
	[ACCELERATE]: { component: AcceleratePhase },
	[ADVANCE]: { component: AdvancePhase },
}

const routeDashboards = Object.keys(dashboardsParamsMap)

const LOCAL_STORAGE_PHASE_KEY = 'PerformDash-NewPhaseDialog'

export default {
    components: {
		PerformDashTitle,
		QAgentLookup,
		InfoDialog,
		NewDashboardUnlockedDialog,
		ActivatePhase,
		AcceleratePhase,
		AdvancePhase,
	},
	mixins: [LeadershipLevelsMixin],
    data () {
        return {
			needsLeadershipLevels: true,
			agent: undefined,
            monthlyPeriod: true,

			doShowTabs: false,
			activeDashRoute: undefined,
			activeDashIndex: 0,
            dashboardsLoading: false,

			doShowDashboardLockedDialog: false,
			dashboardLockedDialogContent: '',

			doShowNewDashboardUnlockedDialog: false,
			newDashboardUnlockedDialogContent: '',

			cmsPageSlug: 'perform-dash-info',
			cmsPage: {
				blocks: null,
				loading: false,
				error: false,
			},

			justMounted: false,
        }
    },
	created () {
        if (this.isDemoMode) {
            this.$router.push('/404')
            return
        }

		if (!this.validateRouteParams()) {
			this.showError(`PerformDash does not have a <strong>${this.$route.params.dashboard}</strong> dashboard.<br>`)
			this.$router.push({ path: '/404' })
			return
		} else {
			this.getRouteData()
			this.loadInfoCmsPage()
		}
	},
	mounted () {
		this.justMounted = true
	},
	activated () {
		if (this.justMounted) {
			this.justMounted = false
		} else {
			this.amplitudeTrack(this.activeDash.amplitudeTrackingID)
		}
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		isCardMobileOrder () {
			return this.$vuetify.breakpoint.mdAndDown
		},
		agentCode () {
			return this.agent?.AgentCode ?? this.user.AgentCode
		},
		activeDash () {
			return this.computedDashboards[this.activeDashIndex]
		},
		activeDashComponent () {
			return dashboardsParamsMap[this.activeDashRoute]?.component
		},
		computedClass () {
			return [
				this.isMobileView && 'perform-dash--mobile',
				this.isTabletView && 'perform-dash--tablet',
				this.isCardMobileOrder && 'perform-dash--mobile-order',
			]
		},
		computedStyle () {
			return {
				'--dash-bg': dashboardsConfig[this.activeDashIndex].dashBg,
				'--card-bg': dashboardsConfig[this.activeDashIndex].cardBg,
			}
		},
		computedHeader () {
			return dashboardsConfig[this.activeDashIndex].headerBg
		},
		currentAgent () {
			return this.agent || this.user.Agent
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.currentAgent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		isAccelerateAvailable () {
			return this.currentAgent.LeadershipLevel !== 'Sales Representative'
		},
		isAdvanceAvailable () {
			const permissions = this.agent ? this.agent.Permissions : this.user.permissions
			if (!permissions) { return this.leadershipOrderRelativeToAO >= 0 }
			return permissions.includes('agent:PerformDashAdvance')
		},
		availableDashboards () {
			return [
				{ phase: 1, disabled: false },
				{ phase: 2, disabled: !this.isAccelerateAvailable },
				{ phase: 3, disabled: !this.isAdvanceAvailable },
				{ phase: 4, disabled: true },
			]
		},
		computedDashboards () {
			return dashboardsConfig.map(dashboard => {
				const disabled = this.availableDashboards.find(({ phase }) => phase === dashboard.phase).disabled
				return { ...dashboard, disabled }
			})
		},
		localStoragePhaseKey () {
			return `${LOCAL_STORAGE_PHASE_KEY}-${this.activeDashIndex + 1}`
		},
    },
	watch: {
		computedDashboards () {
			this.setActiveDash()
		},
		activeDashIndex () {
			const route = dashboardsConfig.find(({ tabIndex }) => tabIndex === this.activeDashIndex).route
			this.setRoute(route)
			this.showNewDashboardUnlockedDialog()
		},
		agent () {
			this.showNewDashboardUnlockedDialog()
		},
		activeDash: {
			immediate: true,
			handler (curr, prev) {
				if (!curr) { return }
				if (curr.phase === prev?.phase) { return }
				this.amplitudeTrack(curr.amplitudeTrackingID)
			},
		},
		'$route' () {
			if (this.$route.name !== 'PerformDash') { return }
			this.getRouteData()
		},
	},
    methods: {
		async loadInfoCmsPage () {
			// check store
			let page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPage.blocks = page.blocks || []
				return Promise.resolve()
			}
			// load from API
			this.cmsPage.loading = true
			await QuilityAPI.getPageWithSlug(this.cmsPageSlug)
			page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPage.blocks = page.blocks || []
			} else {
				this.cmsPage.error = true
				this.showError('Oops! There was a problem loading the cms page with info descriptions.')
			}
			this.cmsPage.loading = false
		},
		setActiveDash () {
			if (this.activeDash.disabled) {
				this.setRoute(ACTIVATE)
			}
		},
		showNewDashboardUnlockedDialog () {
			if (this.activeDashIndex === 0) { return }
			if (this.agent) { return }
			if (localStorage.getItem(this.localStoragePhaseKey) === 'true') { return }
			this.newDashboardUnlockedDialogContent = this.getDashboardUnlockedContent()
			this.doShowNewDashboardUnlockedDialog = true
			localStorage.setItem(this.localStoragePhaseKey, JSON.stringify(true))
		},
		getDashboardUnlockedContent () {
			const blockTitle = dashboardsConfig[this.activeDashIndex].newDashboardUnlockedBlockTitle
			const info = this.cmsPage.blocks?.find(
				(block) => block?.Title.trim?.().toLowerCase() === blockTitle.toLowerCase()
			)?.text
			return info ?? 'Description'
		},
		showDashboardLockedDialog () {
			this.dashboardLockedDialogContent = this.getDashboardLockedContent()
			this.doShowDashboardLockedDialog = true
		},
		getDashboardLockedContent () {
			const disabledDashboardIndex = this.computedDashboards.findIndex(({ disabled }) => disabled)
			const blockTitle = dashboardsConfig[disabledDashboardIndex].dashboardLockedBlockTitle
			const info = this.cmsPage.blocks?.find(
				(block) => block?.Title.trim?.().toLowerCase() === blockTitle.toLowerCase()
			)?.text
			return info ?? 'Description'
		},
		amplitudeTrack (ID) {
			this.$amplitude.track(ID)
		},
		validateRouteParams () {
			const { dashboard } = this.$route.params
			return routeDashboards.includes(dashboard)
		},
		getRouteData () {
			const { dashboard } = this.$route.params
			this.activeDashRoute = dashboard
			this.activeDashIndex = dashboardsConfig.find(({ route }) => route === dashboard).tabIndex
			if (this.$route.props) {
				const { monthlyPeriod, agent } = this.$route.props
				this.agent = agent
				this.monthlyPeriod = !!monthlyPeriod
			}
		},
		setRoute (route) {
			if (this.$route.params.dashboard === route) { return }
			this.$router.push({
				path: `/perform-dash/${route}`,
				props: {
					monthlyPeriod: this.monthlyPeriod,
					agent: this.agent,
				},
			})
		},
    },
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';

$block: perform-dash;

.#{$block} {
	--agent-lookup-width: 21.5rem;
	--header-padding: 0 3rem;
	--header-margin-bottom: 4rem; 
	--header-filters-wrap: nowrap;
	--header-filters-width: unset;

	&--mobile {
		--agent-lookup-width: 100%;
		--header-padding: 0 1rem;
		--header-margin-bottom: 1rem;
		--header-filters-wrap: wrap;
		--header-filters-width: 100%;

		.perform-dash__dashboard {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}

	&--tablet {
		--header-padding: 0 2rem;
		--header-margin-bottom: 1rem;
		--header-filters-width: 100%;
	}

	&--mobile-order {
		.perform-dash__dashboard {
			max-width: 45rem;
			align-self: center;
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: $color-text;
	background-color: var(--dash-bg);

	&__bg {
		position: absolute;
		top: 0;
		height: 240px;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		opacity: 1;
		transition: opacity 0.2s;
	}

	&__bg_hidden {
		opacity: 0;
	}

	&__container {
		max-width: 2100px;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		z-index: 1;
	}

	&__header {
		width: 100%;
		margin-bottom: var(--header-margin-bottom);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 2rem;
		z-index: 1;
	}

	&__header-filters {
		width: var(--header-filters-width);
		padding: var(--header-padding);
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-grow: 1;
		column-gap: 2rem;
		row-gap: 1.25rem;
		flex-wrap: var(--header-filters-wrap);
	}

	&__period-switcher {
		min-width: 9rem;
		margin-bottom: 0.25rem;
		display: flex;
		justify-content: flex-start;
	}

	&__agent-lookup {
		width: var(--agent-lookup-width);

		::v-deep .v-input {
			margin-top: 0;
		}
	}

	.perform-dash__dashboard {
		padding: 1.5rem 3rem 1rem;
	}
}
</style>
