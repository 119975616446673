<template>
	<v-row :key="agentCode" class="activate-phase">
		<v-col cols="12" class="d-flex">
			<v-btn
				class="perform-dash-btn__download-btn ml-auto"
				color="white" text small dark
				:disabled="!isAllowEditing"
				@click="doDialog = true"
			>
				<v-icon color="#46C3B2">icon-download-q</v-icon>
				{{ downloadBtnLabel }}
			</v-btn>
		</v-col>

		<v-col cols="12" lg="4" :order="cardMobileOrder ? 2 : 1" class="activate-phase__card-column">
			<TotalAnalogCloseRatioCard
				:data="cardsData.totalAnalogCloseRatio"
				:info-data="infoData"
				@reload="reloadData"
			/>
			<QuilityLeadMarketplaceCard
				:items="cardsData.activateQLM"
				:monthly-period="monthlyPeriod"
				:info-data="infoData"
				@reload="reloadData"
			/>
			<ExternalLeadSourcesCard
				:items="cardsData.activateExternalLeads"
				:monthly-period="monthlyPeriod"
				:agent="agentCode"
				:info-data="infoData"
				@reload="reloadData"
			/>
		</v-col>

		<v-col cols="12" lg="4" :order="cardMobileOrder ? 1 : 2" class="activate-phase__card-column">
			<div class="activate-phase__card-column-main">
				<ActualVsNeededSubmitCard
					:data="cardsData.actualNeededSubmit"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					@reload="reloadData"
				/>
				<MonthlyIncomeGoalCard
					v-model="incomeGoal"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					:loading="cardsData.actualNeededSubmit.loading"
					:disable="dataLoading"
					:class="isMobileView ? 'mx-2' : 'mx-6'"
				/>
				<SubmitProductMixCard
					:data="cardsData.submitProductMix"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					@reload="reloadData"
				/>
			</div>
			<NoteTakerCard
				:items="cardsData.activateNoteTaker"
				:agent="agentCode"
				:info-data="infoData"
				@reload="reloadData"
			/>
		</v-col>

		<v-col cols="12" lg="4" :order="3" class="activate-phase__card-column">
			<SwitchboardActivityReportCard
				:data="cardsData.activityReport"
				:monthly-period="monthlyPeriod"
				:info-data="infoData"
				@reload="reloadData"
			/>
			<WeeklyActivityTrackerCard
				:items="cardsData.activateActivityTracker"
				:agent="agentCode"
				:info-data="infoData"
				@reload="reloadData"
			/>
			<ProductionTrackingCard
				:data="cardsData.productionTracking"
				:monthly-period="monthlyPeriod"
				:info-data="infoData"
				@reload="reloadData"
			/>
			<SelfLeadershipCard
				:data="cardsData.selfLeadership"
				:monthly-period="monthlyPeriod"
				:info-data="infoData"
				@reload="reloadData"
			/>
		</v-col>

		<DownloadDataDialog
			v-if="doDialog"
			v-model="doDialog"
			:config="downloadDataConfig"
			:agent="agentCode"
			api-path="export_activate_data"
			phase="Activate"
		/>
	</v-row>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI'

import TotalAnalogCloseRatioCard from './DashCards/ActivatePhase/TotalAnalogCloseRatioCard.vue'
import SwitchboardActivityReportCard from './DashCards/ActivatePhase/SwitchboardActivityReportCard.vue'
import ProductionTrackingCard from './DashCards/ActivatePhase/ProductionTrackingCard.vue'
import SelfLeadershipCard from './DashCards/ActivatePhase/SelfLeadershipCard.vue'
import ActualVsNeededSubmitCard from './DashCards/ActivatePhase/ActualVsNeededSubmitCard.vue'
import SubmitProductMixCard from './DashCards/ActivatePhase/SubmitProductMixCard.vue'
import MonthlyIncomeGoalCard from './DashCards/ActivatePhase/MonthlyIncomeGoalCard.vue'
import NoteTakerCard from './DashCards/ActivatePhase/NoteTakerCard.vue'
import ExternalLeadSourcesCard from './DashCards/ActivatePhase/ExternalLeadSourcesCard.vue'
import WeeklyActivityTrackerCard from './DashCards/ActivatePhase/WeeklyActivityTrackerCard.vue'
import QuilityLeadMarketplaceCard from './DashCards/ActivatePhase/QuilityLeadMarketplaceCard.vue'
import DownloadDataDialog from './Components/DownloadDataDialog.vue'

const MONTHLY_INCOME_GOAL_DEFAULT_VALUE = 50000
const modules = [
	'actual_needed_submit',
	'activate_activity_report',
	'activate_analog_close_ratio',
	'activate_qlm',
	'activate_production_tracking',
	'activate_note_taker',
	'activate_activity_tracker',
	'activate_external_lead',
	'activate_self_leadership',
	'activate_submit_product_mix',
]
const getEmptyModulesData = () => modules.reduce((acc, item) => {
	acc[item] = undefined
	return acc
}, {})

const downloadDataConfig = [
	{ module: 'activate_qlm', label: 'Quility Lead Marketplace' },
	{ module: 'activate_external_lead', label: 'External Lead Sources' },
	{ module: 'activate_lead_health', label: 'Lead Health' },
	{ module: 'activate_submit_product_mix', label: 'Submit Product Mix' },
	{ module: 'activate_activity_report', label: 'Switchboard Activity Report' },
	{ module: 'activate_activity_tracker', label: 'Counting What Counts' },
	{ module: 'activate_production_tracking', label: 'Production Tracking' },
	{ module: 'activate_self_leadership', label: 'Self-Leadership' },
]

export default {
    components: {
		TotalAnalogCloseRatioCard,
		SwitchboardActivityReportCard,
		ProductionTrackingCard,
		SelfLeadershipCard,
		ActualVsNeededSubmitCard,
		SubmitProductMixCard,
		MonthlyIncomeGoalCard,
		NoteTakerCard,
		ExternalLeadSourcesCard,
		WeeklyActivityTrackerCard,
		QuilityLeadMarketplaceCard,
		DownloadDataDialog,
	},
	props: {
		agent: {
			type: Object,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
		infoData: Object,
	},
    data () {
        return {
			incomeGoal: null,
			monthlyIncomeGoalPrev: null,
			monthlyPeriodToggleFlag: false,

			dataCache: {},
			dataLoading: false,
			dataLoadingError: false,

			modules,
			modulesData: getEmptyModulesData(),
			issueRate: 0.65,

			doDialog: false,
			downloadDataConfig,
		}
    },
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		downloadBtnLabel () {
			return this.isMobileView
				? 'Download Activate Data'
				: 'Download PerformDash Activate Data'
		},
		agentCode () {
			return this.agent?.AgentCode ?? this.user.AgentCode
		},
		cardsData () {
			return {
				actualNeededSubmit: this.actualNeededSubmitData,
				productionTracking: this.productionTrackingData,
				activityReport: this.modulesData.activate_activity_report,
				totalAnalogCloseRatio: this.modulesData.activate_analog_close_ratio,
				selfLeadership: this.modulesData.activate_self_leadership,
				submitProductMix: this.modulesData.activate_submit_product_mix,
				activateNoteTaker: this.modulesData.activate_note_taker,
				activateExternalLeads: this.modulesData.activate_external_lead,
				activateActivityTracker: this.modulesData.activate_activity_tracker,
				activateQLM: this.modulesData.activate_qlm,
			}
		},
		actualNeededSubmitData () {
			if (!this.modulesData.actual_needed_submit) { return undefined }
			return { ...this.modulesData.actual_needed_submit, suggested: this.suggested }
		}, 
		productionTrackingData () {
			if (!this.modulesData.activate_production_tracking) { return undefined }
			return { ...this.modulesData.activate_production_tracking, suggested: this.suggested }
		},
		agentCommissionRate () {
			const contractLevel = Number(this.agent.ContractLevel)
			return contractLevel ? contractLevel / 100 : 0
		},
		suggested () {
			if (!this.modulesData.actual_needed_submit?.data) { return null }
			const { data } = this.modulesData.actual_needed_submit
			const monthlyIncomeGoal = data.monthlyIncomeGoal || 0
			const incomeGoal = this.incomeGoal || 0
			const neededPlaced = this.agentCommissionRate === 0 ? 0 : Math.ceil(incomeGoal / this.agentCommissionRate / 0.75)
			const neededPlacedProductionTracking = this.agentCommissionRate === 0 ? 0 : Math.ceil(monthlyIncomeGoal / this.agentCommissionRate / 0.75)
			const neededSubmit = Math.ceil(neededPlaced / this.issueRate)
			const neededSubmitProductionTracking = Math.ceil(neededPlacedProductionTracking / this.issueRate)
			return {
				neededPlaced,
				neededPlacedProductionTracking,
				neededSubmit,
				neededSubmitProductionTracking,
				issueRate: this.issueRate,
				...data,
			}
		},
		monthlyIncomeGoal () {
			if (this.incomeGoal === null || this.incomeGoal === undefined) { return this.incomeGoal }
			return this.monthlyPeriod ? this.incomeGoal : Math.round(4 * this.incomeGoal)
		},
		mode () {
			return { agentCode: this.agentCode }
		},
		isAllowEditing () {
			return this.agentCode === this.user.AgentCode
		},
    },
	watch: {
		mode: {
			immediate: true,
			handler () {
				this.updateData()
			},
		},
		dataLoading () {
			this.$emit('loading', this.dataLoading)
		},
		incomeGoal (val) {
			if (this.monthlyPeriodToggleFlag) {
				this.monthlyPeriodToggleFlag = false
				return
			}
			if (val === null || val === undefined) { return }
			this.cacheMonthlyIncomeGoal()
			this.saveMonthlyIncomeGoal()
		},
		monthlyPeriod () {
			this.monthlyPeriodToggleFlag = true
			this.incomeGoal = this.monthlyPeriod
				? Math.round(4 * this.incomeGoal)
				: Math.round(0.25 * this.incomeGoal)
		},
	},
    methods: {
		async updateData () {
			if (!this.dataCache[this.agentCode]) {
				this.dataCache[this.agentCode] = {}
			}
			this.setIncomeGoal(true)
			await this.loadData(this.modules)
			this.setIncomeGoal()
		},
		saveMonthlyIncomeGoal: debounce(async function () {
			if (this.monthlyIncomeGoalPrev === this.monthlyIncomeGoal) {
				return Promise.resolve()
			}
			this.monthlyIncomeGoalPrev = this.monthlyIncomeGoal
			if (this.agentCode !== this.user.AgentCode) {
				return Promise.resolve()
			}
			const res = await QuilityAPI.updateIncomeGoal(this.monthlyIncomeGoal, this.agentCode)
			if (res.error || !res.success) {
				this.showError('Oops! There was a problem saving Monthly Income Goal value in the agent config.', res.cause)
			}
		}, 3000),
		async loadData (modules, isForce) {
			this.resetModulesState(modules)
			// check cache
			const uncachedModules = []
			if (isForce) {
				uncachedModules.push(...modules)
			} else {
				modules.forEach(module => {
					if (this.dataCache[this.agentCode][module] && !this.dataCache[this.agentCode][module].error) {
						this.modulesData[module] = { ...this.dataCache[this.agentCode][module] }
					} else {
						uncachedModules.push(module)
					}
				})
			}
			if (!uncachedModules.length) {
				return Promise.resolve()
			}
			// call API
			this.setModulesLoading(uncachedModules)
			this.dataLoading = true
			this.dataLoadingError = false
			const promises = uncachedModules.map(module => QuilityAPI.loadPerformDashModule(module, this.agentCode))
			const responses = await Promise.all(promises)
			responses.forEach((res, i) => {
				if (res.error && res.text === 'Aborted') {
					// console.error('Aborted')
				} else {
					const state = { loaded: true }
					if (res.error) {
						state.data = null
						state.loading = false
						state.error = true
					} else {
						state.data = this.processData(res)
						state.loading = false
						state.error = false
						this.dataCache[this.agentCode][uncachedModules[i]] = state
					}
					this.modulesData[uncachedModules[i]] = { ...state }
				}
			})
			this.dataLoadingError = Object.values(this.modulesData).every(({ error }) => error)
			this.dataLoading = false
		},
		processData (res) {
			if (this.checkEmptyData(res.data)) { return null }
			return res.data
		},
		checkEmptyData (data) {
			if (!data) { return true }
			if (Array.isArray(data) && !data.length) { return true }
			if (typeof data === 'object' && !Object.keys(data).length) { return true }
			return false
		},
		resetModulesState (modules) {
			modules.forEach(module => {
				if (!this.modulesData[module]) {
					this.modulesData[module] = {}
					this.modulesData[module].data = null
				}
				this.modulesData[module].loading = false
				this.modulesData[module].error = false
				this.modulesData[module].loaded = false
			})
		},
		setModulesLoading (modules) {
			modules.forEach(module => {
				this.modulesData[module].loading = true
			})
		},
		reloadData (module) {
			this.loadData([module], true)
		},
		cacheMonthlyIncomeGoal () {
			if (this.modulesData.actual_needed_submit?.data) {
				this.modulesData.actual_needed_submit.data.monthlyIncomeGoal = this.monthlyIncomeGoal
			}
		},
		setIncomeGoal (flag) {
			if (flag) {
				this.monthlyIncomeGoalPrev = null
				this.incomeGoal = null
			} else {
				const monthlyIncomeGoal = this.modulesData.actual_needed_submit?.data?.monthlyIncomeGoal ?? MONTHLY_INCOME_GOAL_DEFAULT_VALUE
				this.monthlyIncomeGoalPrev = monthlyIncomeGoal
				this.incomeGoal = this.monthlyPeriod ? monthlyIncomeGoal : Math.round(0.25 * monthlyIncomeGoal)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';

$block: activate-phase;

.#{$block} {
	$gap: 2rem;

	height: 100%;
	color: $color-text;

	&__card-column {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: $gap;
	}

	&__card-column-main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		gap: calc(0.5 * #{$gap});
		background-color: var(--card-bg);
		border-radius: $card-border-radius;
	}
}
</style>
